import React from 'react';
import NProgress from 'nprogress';
import {Box, CircularProgress} from '@mui/material';
import Text from "../Text";

class SuspenseLoader extends React.Component<Props, {}> {
    componentDidMount() {
        NProgress.start();
    }

    componentWillUnmount() {
        NProgress.done();
    }

    render() {
        return (
            <Box
                sx={{
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: '100%'
                }}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center">
                <CircularProgress size={64} disableShrink thickness={3}/>
                {this.props.text ? (
                    <div style={{marginTop: 5}}>
                        <Text>{this.props.text}</Text>
                    </div>
                ) : null}
            </Box>
        );
    }
}

type Props = {
    text?: string
}

export default SuspenseLoader;
