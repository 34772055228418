import type {
    ContextSettersType,
    GuildData,
    GuildType
} from "./ClientTypes";
import APIRequests from "../APIRequests";
import EnoPanel from "./EnoPanel";
import ContactStaff from "./ContactStaff";
import Valkyrie from "./Valkyrie";

export * from './ClientTypes';

export class Client {

    public runAppFunction?: () => void;

    public contextSetters: ContextSettersType = {
        logged: placeholderSetter,
        user: placeholderSetter,
        guilds: placeholderSetter,
        currentGuild: placeholderSetter,
        currentGuildData: placeholderSetter
    };

    public readonly requests: APIRequests;

    public readonly enoPanel: EnoPanel;
    public readonly contactStaff: ContactStaff;
    public readonly valkyrie: Valkyrie;

    public constructor() {
        this.requests = new APIRequests('undefined');
        this.enoPanel = new EnoPanel(this);
        this.contactStaff = new ContactStaff(this);
        this.valkyrie = new Valkyrie(this);
    }

    public setContextSetters = (contextSetters: ContextSettersType): void => {
        this.contextSetters = contextSetters;
    }

    public setCurrentGuild = async (guild: GuildType): Promise<void> => {
        const guildData: GuildData | null = await this.enoPanel.fetchGuildData(guild.id);
        if (guildData) {
            this.contextSetters.currentGuild(guild);
            this.contextSetters.currentGuildData(guildData);
        }
    }

    public logout = () => {
        localStorage.removeItem('enopanel_token');
        this.run();
    }

    public run = () => {
        if (this.runAppFunction) {
            this.runAppFunction();
        }
    }
}

const placeholderSetter = (_data: any): void => {};

const client: Client = new Client();

export default client;