import AppContext from "./AppContext";
import {PropsWithChildren, useState} from "react";
import client, {ContextType, GuildData, GuildType, UserType} from "./Client";


export default function AppProvider({children}: PropsWithChildren<{}>) {

    const [logged, setLogged] = useState<boolean>(false);
    const [user, setUser] = useState<UserType | undefined>(undefined);
    const [guilds, setGuilds] = useState<GuildType[]>([]);
    const [currentGuild, setCurrentGuild] = useState<GuildType | undefined>(undefined);
    const [currentGuildData, setCurrentGuildData] = useState<GuildData | undefined>(undefined);

    const context: ContextType = {
        logged,
        user,
        guilds,
        currentGuild,
        currentGuildData
    };

    client.setContextSetters({
        logged: setLogged,
        user: setUser,
        guilds: setGuilds,
        currentGuild: setCurrentGuild,
        currentGuildData: setCurrentGuildData
    });

    return (
        <AppContext.Provider value={context}>
            {children}
        </AppContext.Provider>
    );
}