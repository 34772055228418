import {GuildType} from "../Client";

export const toCamelCase = (obj: AnyObject): any => {
    const result: AnyObject = {};
    for (const k in obj) {
        result[toCamel(k)] = obj[k];
    }
    return result;
}

export const toSnakeCase = (obj: AnyObject): any => {
    const result: AnyObject = {};
    for (const k in obj) {
        result[toSnake(k)] = obj[k];
    }
    return result;
}

interface AnyObject {
    [key: string]: any
}

export const toCamel = (s) => {
    return s.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
            .replace('-', '')
            .replace('_', '');
    });
}

export const toSnake = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const getGuildIconUrl = ({id, icon}: GuildType): string => {
    if (icon) {
        if (icon.startsWith('a_')) {
            return `https://cdn.discordapp.com/icons/${id}/${icon}.gif?size=128`;
        }
        return `https://cdn.discordapp.com/icons/${id}/${icon}.png?size=128`;
    }
    return getDiscordPlaceholderUrl();
}

export const getDiscordPlaceholderUrl = (): string => {
    return '/static/images/placeholders/discord_avatar.png';
}
