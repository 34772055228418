import {createContext} from "react";
import {ContextType} from "./Client/ClientTypes";

const AppContext = createContext<ContextType>({
    logged: false,
    guilds: []
});

export type {ContextType} from "./Client/ClientTypes";

export default AppContext;