import {
    APIRMMenuWithRoles,
    Client,
    ErrorType,
    PartialRMMenu,
    PartialRMRole,
    PartialTCCompleteConfig,
    TCCompleteConfig
} from "./index";
import {Snowflake} from "discord-api-types/globals";
import APIRoutes from "../APIRoutes";

class Valkyrie {
    public constructor(private readonly client: Client) {
    }

    public fetchRMMenus = async (guildId: Snowflake): Promise<APIRMMenuWithRoles[] | null> => {
        const response = await this.client.requests.get(APIRoutes.getRMMenus(guildId));
        return response.status === 200 ? await response.json() : [];
    }

    public createRMMenu = async (guildId: Snowflake, data: PartialRMMenu): Promise<ErrorType[] | undefined> => {
        const response = await this.client.requests.post(APIRoutes.postRMMenus(guildId), {
            body: JSON.stringify(data)
        });
        if (response.status === 422) {
            const data = await response.json();
            return data.errors;
        }
    }

    public updateRMMenu = async (guildId: Snowflake, rmMenuId: number, data: PartialRMMenu): Promise<ErrorType[] | undefined> => {
        const response = await this.client.requests.patch(APIRoutes.patchRMMenu(guildId, rmMenuId), {
            body: JSON.stringify(data)
        });
        if (response.status === 422) {
            const data = await response.json();
            return data.errors;
        }
    }

    public deleteRMMenu = async (guildId: Snowflake, rmMenuId: number): Promise<boolean> => {
        const response = await this.client.requests.delete(APIRoutes.deleteRMMenu(guildId, rmMenuId));
        return response.status === 200;
    }

    public createRMRole = async (guildId: Snowflake, rmMenuId: number, data: PartialRMRole): Promise<ErrorType[] | undefined> => {
        const response = await this.client.requests.post(APIRoutes.postRMRole(guildId, rmMenuId), {
            body: JSON.stringify(data)
        });
        if (response.status === 422) {
            const data = await response.json();
            return data.errors;
        }
    }

    public updateRMRole = async (guildId: Snowflake, rmMenuId: number, rmRoleId: number, data: PartialRMRole): Promise<ErrorType[] | undefined> => {
        const response = await this.client.requests.patch(APIRoutes.patchRMRole(guildId, rmMenuId, rmRoleId), {
            body: JSON.stringify(data)
        });
        if (response.status === 422) {
            const data = await response.json();
            return data.errors;
        }
    }

    public deleteRMRole = async (guildId: Snowflake, rmMenuId: number, rmRoleId: number): Promise<boolean> => {
        const response = await this.client.requests.delete(APIRoutes.deleteRMRole(guildId, rmMenuId, rmRoleId));
        return response.status === 200;
    }

    public fetchTCConfigs = async (guildId: Snowflake): Promise<TCCompleteConfig[] | null> => {
        const response = await this.client.requests.get(APIRoutes.getTCConfigs(guildId));
        return response.status === 200 ? await response.json() : [];
    }

    public createTCConfig = async (guildId: Snowflake, data: PartialTCCompleteConfig): Promise<ErrorType[] | undefined> => {
        const response = await this.client.requests.post(APIRoutes.postTCConfigs(guildId), {
            body: JSON.stringify(data)
        });
        if (response.status === 422) {
            const data = await response.json();
            return data.errors;
        }
    }

    public updateTCConfig = async (guildId: Snowflake, tcConfigId: number, data: PartialTCCompleteConfig): Promise<ErrorType[] | undefined> => {
        const response = await this.client.requests.patch(APIRoutes.patchTCConfig(guildId, tcConfigId), {
            body: JSON.stringify(data)
        });
        if (response.status === 422) {
            const data = await response.json();
            return data.errors;
        }
    }

    public deleteTCConfig = async (guildId: Snowflake, tcConfigId: number): Promise<boolean> => {
        const response = await this.client.requests.delete(APIRoutes.deleteTcConfig(guildId, tcConfigId));
        return response.status === 200;
    }
}

export default Valkyrie;