class APIRequests {

    public constructor(private token: string) {}

    public setToken = (token: string): APIRequests => {
        this.token = token;
        return this;
    }

    public get = async (route: string, init: RequestInit = {}): Promise<Response> => {
        let {headers, ...otherInit}: RequestInit = init;
        if (!headers) {
            headers = {};
        }
        headers = Object.assign(headers, {authorization: `Bearer ${this.token}`});

        return APIRequests.get(route, {
            ...otherInit,
            headers
        });
    }

    public post = async (route: string, init: RequestInit = {}): Promise<Response> => {
        let {headers, ...otherInit}: RequestInit = init;
        if (!headers) {
            headers = {};
        }
        headers = Object.assign(headers, {
            authorization: `Bearer ${this.token}`,
            'content-type': 'application/json'
        });

        return APIRequests.post(route, {
            ...otherInit,
            headers
        });
    }

    public patch = async (route: string, init: RequestInit = {}): Promise<Response> => {
        let {headers, ...otherInit}: RequestInit = init;
        if (!headers) {
            headers = {};
        }
        headers = Object.assign(headers, {
            authorization: `Bearer ${this.token}`,
            'content-type': 'application/json'
        });

        return APIRequests.patch(route, {
            ...otherInit,
            headers
        });
    }

    public delete = async (route: string, init: RequestInit = {}): Promise<Response> => {
        let {headers, ...otherInit}: RequestInit = init;
        if (!headers) {
            headers = {};
        }
        headers = Object.assign(headers, {
            authorization: `Bearer ${this.token}`
        });

        return APIRequests.delete(route, {
            ...otherInit,
            headers
        });
    }

    public static get = async (route: string, init: RequestInit = {}): Promise<Response> => {
        return fetch(route, {
            ...init,
            method: 'GET'
        });
    }

    public static post = async (route: string, init: RequestInit = {}): Promise<Response> => {
        return fetch(route, {
            ...init,
            method: 'POST'
        });
    }

    public static patch = async (route: string, init: RequestInit = {}): Promise<Response> => {
        return fetch(route, {
            ...init,
            method: 'PATCH'
        });
    }

    public static delete = async (route: string, init: RequestInit = {}): Promise<Response> => {
        return fetch(route, {
            ...init,
            method: 'DELETE'
        });
    }
}

export default APIRequests;