import {AppData, Client, GuildData, PremiumGuild} from "./index";
import APIRequests from "../APIRequests";
import APIRoutes from "../APIRoutes";
import {Snowflake} from "discord-api-types/globals";
import {toCamelCase} from "../utils";

class EnoPanel {
    public readonly paypalClientId: string = "AX93lelIGAWqiue864es-DMW14VXrdPNttQ1VLMojZkVoZ2ElS96twnIXhYWq7vySRC3r4paSVL2MT4q";
    public constructor(private readonly client: Client) {}

    public login = async (code: string): Promise<any> => {
        const response = await APIRequests.get(APIRoutes.getLogin(code));
        if (response.status === 200) {
            const result: any = await response.json();
            if (result.logged) {
                localStorage.setItem('enopanel_token', JSON.stringify(result.token));
                await this.checkLogin();
            }
        }
    }

    public checkLogin = async (): Promise<boolean> => {
        const rawToken: string | null = localStorage.getItem('enopanel_token');
        if (rawToken) {
            const token = JSON.parse(rawToken);
            if (token) {
                this.client.requests.setToken(token.token);
                const appData = await this.fetchAppData();
                if (appData) {
                    this.client.contextSetters.user(appData.user);
                    this.client.contextSetters.guilds(appData.guilds);
                    this.client.contextSetters.logged(true);
                    return true;
                }
            }
        }
        this.client.contextSetters.logged(false);
        return false;
    }

    public capturePremiumPayPalPayment = async (guildId: Snowflake, orderId: string): Promise<boolean> => {
        const response = await this.client.requests.get(APIRoutes.getCapturePaypalPayment(guildId, orderId));
        return response.status === 200;
    }

    public fetchPremiumGuild = async (guildId: Snowflake): Promise<PremiumGuild | null> => {
        const response = await this.client.requests.get(APIRoutes.getPremiumGuild(guildId));
        if (response.status === 200) {
            return toCamelCase(await response.json());
        } else {
            return null;
        }
    }

    public getAuthUrl = async (): Promise<string | null> => {
        const response = await APIRequests.get(APIRoutes.getAuthUrl());
        if (response.status === 200) {
            const body: any = await response.json();
            return body.auth_url;
        }
        return null;
    }

    private fetchAppData = async (): Promise<AppData | null> => {
        const response = await this.client.requests.get(APIRoutes.getAppData());
        return response.status === 200 ? response.json() : null;
    }

    public fetchGuildData = async (guildId: Snowflake): Promise<GuildData | null> => {
        const response = await this.client.requests.get(APIRoutes.getGuildData(guildId));
        return response.status === 200 ? response.json() : null;
    }
}

export default EnoPanel;