class APIRoutes {

    private static endpoint: string = "https://api.enopanel.xyz";

    public static getAuthUrl = (): string => {
        return `${APIRoutes.endpoint}/login`;
    }

    public static getLogin = (code: string): string => {
        return `${APIRoutes.getAuthUrl()}?code=${code}`;
    }

    public static getCapturePaypalPayment = (guildId: string, orderId: string): string => {
        return `${APIRoutes.endpoint}/premiums/${guildId}/payment/paypal/capture/${orderId}`;
    }

    public static getPremiumGuild = (guildId: string): string => {
        return `${APIRoutes.endpoint}/premiums/${guildId}`;
    }

    public static getAppData = (): string => {
        return `${APIRoutes.endpoint}/app`;
    }

    public static getGuildData = (guildId: string): string => {
        return `${APIRoutes.endpoint}/guilds/${guildId}`;
    }

    public static getCSConfig = (guildId: string): string => {
        return `${APIRoutes.endpoint}/contact-staff/configs/${guildId}`;
    }

    public static postCSConfig = APIRoutes.getCSConfig;

    public static patchCSConfig = APIRoutes.getCSConfig;

    public static getCSTopics = (guildId: string): string => {
        return `${APIRoutes.endpoint}/contact-staff/topics/${guildId}`;
    }

    public static postCSTopics = APIRoutes.getCSTopics;

    public static patchCSTopic = (guildId: string, topicId: number): string => {
        return `${APIRoutes.postCSTopics(guildId)}/${topicId}`;
    }

    public static deleteCSTopic = APIRoutes.patchCSTopic;

    public static getRMMenus = (guildId: string): string => {
        return `${APIRoutes.endpoint}/valkyrie/role-menus/${guildId}`;
    }

    public static postRMMenus = APIRoutes.getRMMenus;

    public static patchRMMenu = (guildId: string, roleMenuId: number): string => {
        return `${APIRoutes.getRMMenus(guildId)}/${roleMenuId}`;
    }

    public static deleteRMMenu = APIRoutes.patchRMMenu;

    public static postRMRole = APIRoutes.patchRMMenu;

    public static patchRMRole = (guildId: string, roleMenuId: number, roleId: number): string => {
        return `${APIRoutes.postRMRole(guildId, roleMenuId)}/${roleId}`;
    }

    public static deleteRMRole = APIRoutes.patchRMRole;

    public static getTCConfigs = (guildId: string): string => {
        return `${APIRoutes.endpoint}/valkyrie/temporary-channels/${guildId}`;
    }

    public static postTCConfigs = APIRoutes.getTCConfigs;

    public static patchTCConfig = (guildId: string, configId: number): string => {
        return `${APIRoutes.getTCConfigs(guildId)}/${configId}`;
    }

    public static deleteTcConfig = APIRoutes.patchTCConfig;
}

export default APIRoutes;