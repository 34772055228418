import {Client, CSConfig, CSTopic, ErrorType} from "./index";
import {Snowflake} from "discord-api-types/globals";
import APIRoutes from "../APIRoutes";
import {toCamelCase, toSnakeCase} from "../utils";

class ContactStaff {
    public constructor(private readonly client: Client) {}

    public fetchCSConfig = async (guildId: Snowflake): Promise<CSConfig | null> => {
        const response = await this.client.requests.get(APIRoutes.getCSConfig(guildId));
        return response.status === 200 ? toCamelCase(await response.json()) : null;
    }

    public createCSConfig = async (guildId: Snowflake, data: Partial<CSConfig>): Promise<ErrorType[] | undefined> => {
        const response = await this.client.requests.post(APIRoutes.postCSConfig(guildId), {
            body: JSON.stringify(toSnakeCase(data))
        });
        if (response.status === 200) {
            return;
        } else if (response.status === 422) {
            const data = await response.json();
            return data.errors;
        }
    }

    public updateCSConfig = async (guildId: Snowflake, data: Partial<CSConfig>): Promise<ErrorType[] | undefined> => {
        const response = await this.client.requests.patch(APIRoutes.patchCSConfig(guildId), {
            body: JSON.stringify(toSnakeCase(data))
        });
        if (response.status === 200) {
            return;
        } else if (response.status === 422) {
            const data = await response.json();
            return data.errors;
        }
    }

    public fetchCSTopics = async (guildId: Snowflake): Promise<CSTopic[]> => {
        const response = await this.client.requests.get(APIRoutes.getCSTopics(guildId));
        if (response.status === 200) {
            const data: any[] = await response.json();
            return data.map(toCamelCase);
        }
        return [];
    }

    public createCSTopic = async (guildId: Snowflake, data: Partial<CSTopic>): Promise<ErrorType[] | undefined> => {
        const response = await this.client.requests.post(APIRoutes.postCSTopics(guildId), {
            body: JSON.stringify(toSnakeCase(data))
        });
        if (response.status === 200) {
            return;
        } else if (response.status === 422) {
            const data = await response.json();
            return data.errors;
        }
    }

    public updateCSTopic = async (guildId: Snowflake, topicId: number, data: Partial<CSTopic>): Promise<ErrorType[] | undefined> => {
        const response = await this.client.requests.patch(APIRoutes.patchCSTopic(guildId, topicId), {
            body: JSON.stringify(toSnakeCase(data))
        });
        if (response.status === 200) {
            return;
        } else if (response.status === 422) {
            const data = await response.json();
            return data.errors;
        }
    }

    public deleteCSTopic = async (guildId: Snowflake, topicId: number): Promise<boolean> => {
        const response = await this.client.requests.delete(APIRoutes.deleteCSTopic(guildId, topicId));
        return response.status === 200;
    }
}

export default ContactStaff;