import {useRoutes} from 'react-router-dom';
import router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import {CssBaseline} from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import React from "react";
import BaseLayout from "./layouts/BaseLayout";
import Login from "./content/home/Login";
import SuspenseLoader from "./components/SuspenseLoader";
import AppContext, {ContextType} from "./enopanel/AppContext";
import client from "./enopanel/Client";

class App extends React.Component<{}, States> {

    static contextType = AppContext;

    context: ContextType;

    state: States = {
        loading: true,
        authUrl: ''
    };

    componentDidMount() {
        client.runAppFunction = this.runApp;
        this.startApp();
    }

    public startApp = () => {
        client.enoPanel.checkLogin().then((logged) => {
            if (!logged) {
                client.enoPanel.getAuthUrl().then((authUrl) => {
                    if (authUrl) {
                        this.setState({
                            authUrl,
                            loading: false
                        });
                    }
                });
            } else {
                this.setState({
                    loading: false
                });
            }
        });
    }

    public runApp = () => {
        if (!this.state.loading) {
            this.setState({
                loading: true
            }, () => {
                this.startApp();
            });
        } else {
            this.startApp();
        }
    }

    render() {
        return (
            <>
                {this.state.loading || !this.context.logged ? (
                    <ThemeProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <CssBaseline/>
                            <BaseLayout>
                                {this.state.loading ? (<SuspenseLoader text="Chargement en cours"/>) :
                                    <Login authUrl={this.state.authUrl}/>}
                            </BaseLayout>
                        </LocalizationProvider>
                    </ThemeProvider>
                ) : (
                    <LoggedApp/>
                )}
            </>
        );
    }
}

function LoggedApp() {
    const content = useRoutes(router());

    return (
        <ThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <CssBaseline/>
                {content}
            </LocalizationProvider>
        </ThemeProvider>
    );
}

type States = {
    loading: boolean,
    authUrl: string
}

export default App;
